import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TalkToUs from "../components/talkToUs"

const EmergencyPage = () => (
  <Layout>
    <SEO
      title="Emergency Software Services"
      keywords={[`data center`, `software`, `failure`, `recovery`]}
    />
    <h1>Emergency Software Services</h1>
    <p>
      Do you have a cloud migration gone bad? Has your engineering team lost a
      key player? Do you have legacy software that suddenly stopped working out
      of the blue?
    </p>
    <TalkToUs>Reach Out Now</TalkToUs>
    <h2>How It Works</h2>
    <ol>
      <li>
        You connect us with a point person who can get us access to wherever
        your software runs (data center, AWS, GCP, Azure, on-prem, etc.)
      </li>
      <li>
        We agree on an hourly rate and get authorization in 10 hour increments.
      </li>
      <li>
        You and your team get realtime updates via private chat and audio
        streams.
      </li>
      <li>
        We will remediate the problems and provide guidance for next steps.
      </li>
    </ol>
  </Layout>
)

export default EmergencyPage
