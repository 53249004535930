import React from "react"

const TalkToUs = ({ children }) => {
  return (
    <a
      style={{
        alignSelf: "center",
        display: "block",
        width: "10rem",
        padding: ".5rem",
        color: "white",
        backgroundColor: "var(--primary)",
        textAlign: "center",
        borderRadius: "5px",
        textDecoration: "none",
        fontWeight: "600",
        boxShadow:
          "0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        cursor: "pointer",
        marginBottom: "2rem",
      }}
      onClick={e => {
        $crisp.push(["do", "chat:open"]) // eslint-disable-line no-undef
        e.preventDefault()
      }}
      href="mailto:alec@mulch.dev"
    >
      {children}
    </a>
  )
}

export default TalkToUs
